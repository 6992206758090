<template>
  <button class="dotmenu" v-click-outside="closeMenu" @click="toggleMenu" :class="{ 'is-open': isOpen }" type="button">
    <span class="dotmenu__handle">{{ handleText }}</span>
    <ul class="dotmenu__list" :class="{ 'dotmenu__list--highlight-rows': highlightRows }">
      <slot></slot>
    </ul>
  </button>
</template>

<script>
export default {
  props: {
    keepOpen: {
      type: Boolean,
      default: false
    },
    handleText: {
      type: String,
      default: '...'
    },
    highlightRows: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isOpen: false
    }
  },
  methods: {
    toggleMenu: function(e) {
      const handleClicked = e.target.classList.contains('dotmenu__handle')
      if (handleClicked) {
        this.isOpen = !this.isOpen
      } else if (!this.keepOpen) {
        this.isOpen = false
      }
    },
    closeMenu: function() {
      this.isOpen = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../variables.scss';

.dotmenu {
  @extend %cleanbutton;

  position: relative;

  cursor: default;

  &__handle {
    z-index: 750;
    display: inline-block;
    height: 2rem;
    padding: 0 0.75rem;

    cursor: pointer;

    line-height: 2rem;
  }

  &__list {
    display: none;
    position: absolute;
    z-index: 1000;
    background: #fff;
    list-style: none;
    padding: 0.75rem 0;
    right: 0;
    top: calc(100% + 0.5rem);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
    border-radius: 5px;

    li {
      white-space: nowrap;
      text-align: right;
      padding: 0.25rem 1rem;

      a {
        color: inherit;
        display: block;

        &:hover {
          text-decoration: none;
        }
      }
    }

    &--highlight-rows {
      li:hover {
        background: #f2f2f2;
        cursor: pointer;
      }
    }
  }

  &.is-open {
    outline: none;

    .dotmenu__list {
      display: block;
    }
  }
}
</style>
