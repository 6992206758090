<template>
  <BaseLoader v-if="!$store.getters.isLoaded(['organizations'])" />
  <div v-else>
    <h1 class="h2 mb-4">
      Kiosk
    </h1>
    <table class="table">
      <thead class="thead-light">
        <tr>
          <th>Name</th>
          <th>Last Order</th>
          <th>Next Order</th>
          <th>Interval</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="organization in organizations" :key="organization.id">
          <td class="align-middle">{{ organization.name }}</td>
          <td v-if="organization.kioskSettings.lastOrder" class="align-middle">
            {{ organization.kioskSettings.lastOrder | date }}
          </td>
          <td class="align-middle" v-else style="color: #bbb;">-/-</td>
          <td>
            <input
              type="date"
              class="form-control"
              :style="`color: #${organization.kioskSettings.nextOrder === null ? 'bbb' : '000'};`"
              :name="organization.id"
              :value="organization.kioskSettings.nextOrder"
              @change="setNextOrder"
            />
          </td>
          <td>
            <select
              class="custom-select"
              :name="organization.id"
              :style="`color: #${organization.kioskSettings.interval === null ? 'bbb' : '000'};`"
              v-model="organization.kioskSettings.interval"
              @change="setInterval($event)"
            >
              <option :value="null">---</option>
              <option :value="0.5">½ week</option>
              <option :value="1">1 week</option>
              <option :value="2">2 weeks</option>
              <option :value="3">3 weeks</option>
              <option :value="4">4 weeks</option>
            </select>
          </td>
          <td>
            <DotMenu>
              <li @click="() => copyToClipboard(organization.id)">Copy Organization ID</li>
            </DotMenu>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { db } from '@/shared/firebase'
import DotMenu from '@/shared/DotMenu'
import moment from 'moment'

export default {
  components: { DotMenu },
  computed: {
    organizations() {
      let organizations = this.$store.state.organizations
        .filter(o => o.features.kiosk && !o.demo)
        .sort((a, b) => (a.name > b.name ? 1 : -1))
      organizations.forEach(org => {
        org.kioskSettings = {
          lastOrder: null,
          nextOrder: null,
          interval: null,
          ...org.kioskSettings
        }
      })
      return organizations
    }
  },
  methods: {
    setNextOrder(e) {
      const { value, name } = e.target
      if (value !== '') {
        const weekDay = new Date(value).getDay()
        if ([0, 6].includes(weekDay)) return alert(`Orders can only be scheduled on weekdays`)
      }
      db.doc(`organizations/${name}`).update({
        ['kioskSettings.nextOrder']: value !== '' ? value : null
      })
    },
    setInterval(e) {
      const { value, name } = e.target
      db.doc(`organizations/${name}`).update({
        ['kioskSettings.interval']: value !== '' ? parseFloat(value) : null
      })
    }
  },
  filters: {
    date(date) {
      return moment(date.toDate()).format('DD.MM.YYYY')
    }
  }
}
</script>
